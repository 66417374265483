<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" size="mini">
        <el-form-item label="代理人:" prop="name">
          <el-input clearable v-model.trim="table.params.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="联系电话:" prop="phone">
          <el-input clearable v-model.trim="table.params.phone" placeholder="请输入" maxlength="11"></el-input>
        </el-form-item>
        <el-form-item label="所在区域:" prop="area">
          <el-cascader
            style="width:100%;"
            v-model="table.params.area"
            :options="areaArr"
            :props="{ checkStrictly: false}"
            @change="selArea">
        </el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button class="theneBtn" type="primary" icon="el-icon-search" @click="onSearch">查询 </el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-button size="mini" type="primary" plain icon="el-icon-plus" @click="showEdit">创建代理商</el-button>
    </div>

    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      :showSelect="false"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    > 
      <template v-slot:province_str="{row}">
        <span>{{row.province_str}}{{row.city_str}}{{row.area_str}}</span>
      </template>
      <template v-slot:status="{row}">
        <span>{{row.status == 1 ? '正常': '禁用'}}</span>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" icon="el-icon-edit" @click="showEdit(row)">编辑</el-button>
        <el-button type="text" @click='showPass(row)'>修改密码</el-button>
        <el-button type="text" :class="row.status == 1 ? 'delColor' : 'sussColor'" @click="handleStatus(row)">{{row.status == 1 ? '关闭' : '恢复'}}</el-button>
      </template>
    </VTable>

    <edit ref="edit" :areaArr="areaArr" @refresh="getTable"></edit>

    <password ref="pass"></password>
    
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Edit from './components/Edit.vue';
import Password from './components/Password.vue';
import { mixinTable } from '@/mixins/table.js';
export default {
  name: 'BannerList',
  components: {
    VTable,
    Edit,
    Password
  },
  mixins: [mixinTable],
  data() {
    return {
      field: [
        { name: "name", label: "代理人", hidden: false },
        { name: "phone", label: "联系电话", hidden: false },
        { name: "account", label: "代理账号", hidden: false },
        { name: "province_str", label: "所在地区", width: '200', showTooltip:true, hidden: false },
        { name: "money", label: "已缴纳代理费/元", hidden: false },
        { name: "worker_count", label: "邀请师傅量", hidden: false },
        { name: "status", label: "状态", hidden: false },
        { name: "create_time", label: "创建时间", width:'160', hidden: false },
        { name: "action", label: "操作", fixed:"right", width: "240", hidden: false }
      ],
      areaarr: [],
      table: {
        loading: false,
        params: {
          name: '',
          phone: '',
          province: '',
          city: '',
          area: '',
          status:'', // 1正常，2禁用
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      areaArr: [],
    }
  },
  created() {
    this.getTable();
    this.getArea();
  },
  methods: {
    getTable() {
      delete this.areaarr
      this.$http.get('/admin/flex_agency/list', { params:this.table.params }).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    reset() {
      Object.keys(this.table.params).forEach(item => {
        this.table.params[item] = ''
        this.table.params.page = 1
        this.table.params.count = 10
      })
      this.getTable();
    },
    selArea(val) {
      this.table.params.province = val[0]
      this.table.params.city = val[1]
      this.table.params.area = val[2]
    },
    // 获取省、市、区
    getArea() {
      this.$http.post('/common/district/all',{}).then(res => {
        if(res.code == 1) {
          this.areaArr = res.data.list;
        }
      })
    },
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      if (!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    // 查看
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 关闭/恢复
    handleStatus(row) {
      let str = row.status == 1 ? '关闭' : '恢复';
      this.$confirm(`请确认${str}?`,"提示", {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post('/admin/flex_agency/operation', { id: row.id }).then(res => {
          if(res.code === 1) {
            this.$message.success('操作成功！')
            this.getTable();
          } else {
            this.$message.error(res.data)
          }
        })
      }).catch(() => {})
    },
    showPass(row) {
      let dom = this.$refs.pass
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    
  }
}
</script>

<style scoped>
.delColor {
  color: #f56c6c;
}
.sussColor {
  color: #67c23a;
}
</style>