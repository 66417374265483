<template>
  <el-dialog
    append-to-body
    width="600px"
    :title="`${isChange ? '修改' : '新增'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref="elFormDom"
      label-width="130px"
      label-position="right"
      :model="form.data"
      :rules="form.rules"
    >
      <el-form-item prop="name" label="负责人">
        <el-input
          v-model="form.data.name"
          clearable
          placeholder="请输入负责人姓名"
        ></el-input>
      </el-form-item>
      <el-form-item prop="phone" label="联系电话">
        <el-input
          v-model="form.data.phone"
          maxlength="11"
          clearable
          placeholder="请输入联系电话"
        ></el-input>
      </el-form-item>
      <el-form-item prop="account" label="登录账号">
        <el-input
          v-model="form.data.account"
          maxlength="11"
          clearable
          :disabled="isChange"
          placeholder="请输入账号"
        ></el-input>
      </el-form-item>
      <el-form-item prop="password" label="登录密码" v-if="!isChange">
        <el-input
          v-model="form.data.password"
          clearable
          placeholder="请输入密码"
        ></el-input>
      </el-form-item>
      <el-form-item prop="area" label="所在地区">
        <el-cascader
          style="width:100%;"
          v-model="form.data.area"
          :options="areaArr"
          :props="{ checkStrictly: false}">
        </el-cascader>
      </el-form-item>
      <el-form-item prop="money" label="已缴纳保证金">
        <el-input
          v-model="form.data.money"
          clearable
          :readonly="isChange"
          placeholder="请输入金额"
          :disabled="isChange"
        ><template slot="suffix">元</template></el-input>
      </el-form-item>
      <el-form-item prop="status" label="状态">
        <el-radio-group v-model="form.data.status" :disabled="isChange">
          <el-radio :label="1">开启</el-radio>
          <el-radio :label="2">关闭</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click="toggle(false)">取消</el-button>
        <el-button
          type="primary"
          :loading="form.loading"
          :disabled="form.loading"
          @click="confirm"
          >确定</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import common from  '@/util'
  import { REG_EXP } from '@/util'
  import MyVueCroppers from "@/components/MyVueCroppers.vue";
  export default {
    name: 'AccountEdit',
    props:['areaArr'],
    components: {
      MyVueCroppers,
    },
    data() {
      return {
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            name: '',
            account: '',
            phone: '',
            password: '',
            province: '',
            city: '',
            area: '',
            money: '',
            status: 1,
          },
          rules: {
            name: [{required: true, message: "请输入姓名！", trigger: "change"}],
            account: [
              {required: true, message: "请输入账号！", trigger: "change"},
              {pattern: REG_EXP.mobilePhone, message: "账号格式不正确", trigger: "blur"}
            ],
            phone: [
              {required: true, message: "请输入联系电话！", trigger: "change"},
              {pattern: REG_EXP.mobilePhone, message: "手机号不正确", trigger: "blur"}
            ],
            password: [{required: true, message: "请输入密码！", trigger: "change"}],
            money: [
              {required: true, message: "请输入保证金！", trigger: "change"},
              { pattern: REG_EXP.money, message: "请填写正确的金额格式，如：188.88", trigger: "blur"}
            ],
            area: [{required: true, message: "请选择所在地区！", trigger: ["change","blur"]}],
            status: [{required: true}]
          }
        },
      }
    },
    methods: {
      // 获取详情
      getDetail(row) {
        this.isChange = true
        common.deepClone(this.form.data, row)
        this.form.data.area = [row.province, row.city, row.area];
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            this.form.data.province = this.form.data.area[0];
            this.form.data.city = this.form.data.area[1];
            this.form.data.area = this.form.data.area[2];
            let apiUrl = !this.isChange ? '/admin/flex_agency/add' : '/admin/flex_agency/edit'
            this.form.loading = true;
            this.$http.post(apiUrl, this.form.data).then(res => {
              if(res.code === 1) {
                this.toggle(false)
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
           }).finally(() => {
             this.form.loading = false;
           })
          }
        })
      },
      
    }
  }
</script>
